import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/root';

export default function configureStore() {
  const store = createStore(
    rootReducer,
    applyMiddleware(thunk),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
	  module.hot.accept('./reducers/root', () => {
        store.replaceReducer(rootReducer);
	  });
    }
  }

  return store;
}
