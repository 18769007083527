import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import Search from '@material-ui/icons/Search';
import { useSearchInputStyles } from '@mui-treasury/styles/input/search';
import Item from './Item';
import MinimalSelect from './MinimalSelect';
import { API_URLS } from '../../config';
import { postRequest, postRequestJSON } from '../../utils/network';
import CreateCard from '../CreateCard';

const classes = {
  root: {
    marginTop: '2%',
    flexGrow: 1,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '20px',
  },
};

const sortFunctions = {
  name: (a, b) => a.Name > b.Name,
  completionAsc: (a, b) => a.Completion > b.Completion,
  completionDesc: (a, b) => a.Completion < b.Completion,
};

const defaultState = {
  sortFunction: 'name',
  els: [],
  itemsToMove: [],
};

const reduceFolder = (els, state, setState) => {
  els.sort(sortFunctions[state.sortFunction]);
  setState({
    ...state,
    els: els.map((el) => ({
      ...el,
      isOpen: false,
      isRenamming: false,
      isCreating: false,
    })),
  });
};

const loadFolder = async (folderId, state, setState) => {
  try {
    console.log(API_URLS.FOLDER_LIST_INSIDE + (folderId === undefined ? '' : folderId));
    const r = await fetch(API_URLS.FOLDER_LIST_INSIDE + (folderId === undefined ? '' : folderId));
    const j = await r.json();
    reduceFolder(j.Els, { ...state, fullPath: j.FullPath }, setState);
  } catch (e) {
    toastr.error(e);
  }
};

const moveElements = async (itemsFrom, itemTo, setState) => {
  try {
    await postRequestJSON(
      { itemsFrom, itemTo: parseInt(itemTo, 10) },
      API_URLS.MOVE_ELEMENTS,
    );
  } catch (e) {
    toastr.error(e);
    return;
  }
  loadFolder(itemTo, defaultState, setState);
};

const makeSearch = async (query, state, setState) => {
  try {
    const r = await postRequest(
      {
        query,
      },
      API_URLS.SEARCH,
    );
    const j = await r.json();
    reduceFolder(j, state, setState);
  } catch (e) {
    toastr.error(e);
  }
};

const redirectToRoot = async (history) => {
  try {
    const r = await postRequest(undefined, API_URLS.GET_ROOT_ID);
    const l = await r.text();
    history.push(`/explore/${l}`);
  } catch (e) {
    toastr.error(e);
  }
};

function Explore() {
  const { folderId } = useParams();
  const [state, setState] = useState({ ...defaultState });
  const [idToDelete, setIdToDelete] = useState();

  const history = useHistory();

  useEffect(() => {
    if (folderId === undefined) {
      redirectToRoot(history);
      return;
    }
    loadFolder(
      folderId,
      {
        ...defaultState,
        sortFunction: state.sortFunction,
        itemsToMove: state.itemsToMove,
      },
      setState,
    );
  }, [folderId, setState, state.sortFunction, state.itemsToMove, history]);

  const handleDelete = async () => {
    try {
      if (idToDelete.type === 'folder') {
        await postRequest(
          undefined,
          `${API_URLS.DELETE_FOLDER_FROM_ID}/${idToDelete.id}`,
        );
      } else {
        await postRequest(
          undefined,
          `${API_URLS.DELETE_CARD}/${idToDelete.id}`,
        );
      }
    } catch (e) {
      toastr.error(e);
      return;
    }

    // Reload the page
    setIdToDelete();
    loadFolder(folderId, state, setState);
  };

  return (
    <span>
      <TopBar folderId={folderId} state={state} setState={setState} />
      <Grid container>
        <Grid
          item
          id="explorerListContainer"
          style={
            state.isCreateWindowOpen ? {
              overflowY: 'scroll',
              height: `calc(100vh - ${document.getElementById('explorerListContainer').offsetTop}px)`,
            } : {}
          }
          xs={state.isCreateWindowOpen ? 6 : 12}
        >
          <List key="explorerList">
            {state.els.map((el, index) => (
              <Item
                key={el.Type === 'card' ? `card${el.ID}` : `folder${el.ID}`}
                el={el}
                state={state}
                setState={setState}
                setIdToDelete={setIdToDelete}
                history={history}
                index={index}
                parentFolder={folderId}
                loadFolder={loadFolder}
              />
            ))}
          </List>
        </Grid>
        {state.isCreateWindowOpen && (
          <Grid xs={6}>
            <CreateCard
              folderPath={state.fullPath}
              fullSize
              noTitle
              creationCallback={() => {
                loadFolder(folderId, state, setState);
              }}
            />
          </Grid>
        )}
      </Grid>
      {state.itemsToMove.length > 0 && (
        <MoveAndCancel folderId={folderId} state={state} setState={setState} />
      )}
      {idToDelete !== undefined && (
        <DeleteModal
          idToDelete={idToDelete}
          setIdToDelete={setIdToDelete}
          handleDelete={handleDelete}
        />
      )}
    </span>
  );
}

export default Explore;

const MoveAndCancel = (props) => (
  <Grid
    container
    style={{
      position: 'fixed',
      zIndex: 1000,
      right: 'calc(1em)',
      top: 'calc(100% - 4em)',
    }}
    justify="flex-end"
    spacing={2}
  >
    <Grid item>
      <Button
        className="animate__animated animate__bounceIn"
        color="secondary"
        variant="contained"
        onClick={() => {
          props.setState({
            ...props.state,
            itemsToMove: [],
          });
        }}
      >
        Cancel
      </Button>
    </Grid>
    <Grid item>
      <Button
        color="primary"
        style={{
          animationDelay: '50ms',
        }}
        className="animate__animated animate__bounceIn"
        variant="contained"
        onClick={() => moveElements(props.state.itemsToMove, props.folderId, props.setState)}
      >
        Move here
      </Button>
    </Grid>
  </Grid>
);

const TopBar = (props) => (
  <Grid
    style={{
      paddingTop: '1%',
    }}
    container
    spacing={3}
  >
    <Grid
      container
      item
      xs={12}
      sm={6}
    >
      <Grid item xs={12} sm={7}>
        <InputBase
          classes={useSearchInputStyles()}
          style={{
            width: '85%',
          }}
          placeholder="Search..."
          startAdornment={<Search />}
          onChange={(e) => {
            if (e.target.value === '') {
              loadFolder(props.folderId, props.state, props.setState);
              return;
            }

            makeSearch(e.target.value, props.state, props.setState);
          }}
        />
      </Grid>
      <Grid item container justify="center" xs={12} sm={5}>
        <MinimalSelect
          value={props.state.sortFunction}
          onChange={(event) => {
            loadFolder(
              props.folderId,
              { ...props.state, sortFunction: event.target.value },
              props.setState,
            );
          }}
          items={[
            {
              name: 'By name',
              value: 'name',
            },
            {
              name: 'Most complete',
              value: 'completionDesc',
            },
            {
              name: 'Least complete',
              value: 'completionAsc',
            },
          ]}
        />
      </Grid>
    </Grid>
    <Grid
      container
      item
      justify="flex-end"
      spacing={1}
      sm={6}
    >
      <Hidden smDown>
        <Grid item m={3}>
          <Button
            variant="contained"
            style={{
              // I heard you like black magic
              marginTop: 'calc(1.1876em / 3)',
              minWidth: '18ch',
            }}
            color="primary"
            onClick={() => {
              props.setState({ ...props.state, isCreateWindowOpen: !props.state.isCreateWindowOpen });
            }}
          >
            {!props.state.isCreateWindowOpen ? 'Create card' : 'Close card'}
          </Button>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={3}>
        <Button
          variant="contained"
          style={{
          // I heard you like black magic
            marginTop: 'calc(1.1876em / 3)',
          }}
          color="primary"
          onClick={() => {
            const nels = [...props.state.els];
            nels.unshift({
              Type: 'folder',
              ID: -1,
              Name: '',
              Completion: 0,
              DaysSinceLastUpdate: 0,
              isOpen: false,
              isRenaming: false,
              isCreating: true,
            });

            props.setState({ ...props.state, els: nels });
          }}
        >
          New folder
        </Button>
      </Grid>
    </Grid>
  </Grid>
);
TopBar.propTypes = {
  folderId: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.objectOf({
    els: PropTypes.array,
  }).isRequired,

};

const DeleteModal = ({ idToDelete, setIdToDelete, handleDelete }) => (
  <Modal
    open={idToDelete !== undefined}
    onClose={() => setIdToDelete(undefined)}
  >
    <div style={classes.modal}>
      <h2 id="simple-modal-title">Are you sure ?</h2>
      <p id="simple-modal-description">
        Please confirm the
        {' '}
        {idToDelete.type}
        {' '}
        deletion
        <br />
        <br />
        <Button
          variant="contained"
          onClick={() => {
            handleDelete();
          }}
          color="secondary"
        >
          Delete
        </Button>
      </p>
    </div>
  </Modal>
);
DeleteModal.propTypes = {
  idToDelete: PropTypes.number,
  setIdToDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
DeleteModal.defaultProps = {
  idToDelete: undefined,
};
