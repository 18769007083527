/* eslint react/prop-types: 0 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import 'animate.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ProgressDots from '../ProgressDots';
import { postRequest } from '../../utils/network';
import { API_URLS } from '../../config';

const renameElementAPI = async (
  value,
  elementId,
  parentFolder,
  appState,
  setAppState,
  loadFolder,
  type,
) => {
  try {
    await postRequest(
      {
        id: elementId,
        value,
      },
      type === 'card' ? API_URLS.RENAME_CARD : API_URLS.RENAME_FOLDER,
    );
    loadFolder(parentFolder, appState, setAppState);
  } catch (e) {
    toastr.error(e);
  }
};

const CreateInput = ({
  parentFolder, state, setState, loadFolder,
}) => {
  const [value, setValue] = useState();

  const createElement = async () => {
    try {
      await postRequest(
        {
          name: value,
          parentID: parentFolder,
        }, API_URLS.CREATE_FOLDER,
      );
      loadFolder(parentFolder, state, setState);
    } catch (e) {
      toastr.error(e);
    }
  };

  return (
    <Input
      value={value}
      className="animate__animated animate__flipInX"
      autoFocus
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          createElement();
        }
      }}
      onBlur={() => {
        createElement();
      }}
    />
  );
};

const RenameInput = ({
  initialValue,
  state,
  setState,
  elementId,
  loadFolder,
  parentFolder,
  type,
}) => {
  const [value, setValue] = useState(initialValue);

  const renameElement = () => {
    renameElementAPI(
      value,
      elementId,
      parentFolder,
      state,
      setState,
      loadFolder,
      type,
    );
  };

  return (
    <Input
      value={value}
      className="animate__animated animate__flipInX"
      autoFocus
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
          renameElement();
        }
      }}
      onBlur={() => {
        renameElement();
      }}
    />
  );
};
RenameInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  state: PropTypes.objectOf({
    els: PropTypes.array,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  elementId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  loadFolder: PropTypes.func.isRequired,
  parentFolder: PropTypes.string.isRequired,
};

const Item = ({
  el,
  state,
  setState,
  setIdToDelete,
  history,
  index,
  parentFolder,
  loadFolder,
}) => {
  const addToMoveList = (id, type, checked) => {
    // Remove from the list
    if (!checked) {
      let itemIndex = -1;
      for (let i = 0; i < state.itemsToMove.length; i += 1) {
        if (state.itemsToMove[i].id === id && state.itemsToMove.type === type) {
          itemIndex = i;
          break;
        }
      }

      const c = state.itemsToMove.slice();
      const s = {
        ...state,
        itemsToMove: c.splice(itemIndex, 1),
      };
      s.els[index].isOpen = false;
      setState(s);
      return;
    }

    const s = {
      ...state,
      itemsToMove: state.itemsToMove.concat({
        id,
        type,
      }),
    };
    s.els[index].isOpen = false;
    setState(s);
  };

  const isChecked = (id, type) => {
    for (let i = 0; i < state.itemsToMove.length; i += 1) {
      if (
        state.itemsToMove[i].id === id
        && state.itemsToMove[i].type === type
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <span>
      <ListItem
        key={`${el.ID}item`}
        button={!el.isRenaming}
        className={(() => (el.isRenaming ? '' : 'animate__animated animate__fadeInDown'))()}
        style={{
          animationDuration: '150ms',
          animationDelay: `${index * 15}ms`,
        }}
        onClick={() => {
          if (el.isRenaming) {
            return;
          }
          if (el.Type === 'folder') {
            history.push(`/explore/${el.ID}`);
          } else {
            history.push(`/showCard/${el.ID}`);
          }
        }}
      >
        {state.itemsToMove.length > 0 && (
          <Checkbox
            checked={isChecked(el.ID, el.Type)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              addToMoveList(el.ID, el.Type, e.target.checked);
            }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        )}
        <ListItemAvatar>
          <Avatar>
            {(() => (el.Type === 'folder' ? <FolderIcon /> : <ImageIcon />))()}
          </Avatar>
        </ListItemAvatar>
        <Grid container direction="column">
          <Grid item>
            {(() => {
              if (el.isRenaming) {
                return (
                  <RenameInput
                    initialValue={el.Name}
                    state={state}
                    setState={setState}
                    loadFolder={loadFolder}
                    elementId={el.ID}
                    parentFolder={parentFolder}
                    type={el.Type}
                  />
                );
              } if (el.isCreating) {
                return (
                  <CreateInput
                    parentFolder={parentFolder}
                    state={state}
                    setState={setState}
                    loadFolder={loadFolder}
                  />
                );
              }
              return (<Typography>{el.Name}</Typography>);
            }
            )()}
          </Grid>
          <Grid item>
            { el.Type !== 'folder' ? (
              <ProgressDots
                score={el.Completion}
                DaysSinceLastUpdate={el.DaysSinceLastUpdate}
              />
            ) : (
              <FolderProgress el={el} />
            )}
          </Grid>
        </Grid>
        <ArrowButton isOpen={el.isOpen} state={state} setState={setState} index={index} />
      </ListItem>
      <Collapse in={el.isOpen} timeout="auto" unmountOnExit>
        {el.isOpen && (
          <SubMenu
            addToMoveList={addToMoveList}
            el={el}
            index={index}
            state={state}
            setState={setState}
            setIdToDelete={setIdToDelete}
          />
        )}
      </Collapse>
    </span>
  );
};

Item.propTypes = {
  state: PropTypes.shape({
    els: PropTypes.arrayOf(
      PropTypes.shape({
        Type: PropTypes.string,
        ID: PropTypes.number,
        Name: PropTypes.string,
        Completion: PropTypes.number,
        DaysSinceLastUpdate: PropTypes.number,
        isOpen: PropTypes.bool,
        isRenaming: PropTypes.bool,
      }),
    ).isRequired,
    itemsToMove: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
      }),
    ),
  }).isRequired,
  el: PropTypes.shape({
    Type: PropTypes.string,
    ID: PropTypes.number,
    Name: PropTypes.string,
    Completion: PropTypes.number,
    DaysSinceLastUpdate: PropTypes.number,
    isOpen: PropTypes.bool,
    isRenaming: PropTypes.bool,
  }).isRequired,
  setState: PropTypes.func.isRequired,
  setIdToDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  parentFolder: PropTypes.string.isRequired,
  loadFolder: PropTypes.func.isRequired,
};

export default Item;

const SubMenu = ({
  el, index, addToMoveList, setIdToDelete, state, setState,
}) => (
  <Grid container justify="flex-end">
    <Grid item>
      <ListItem>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIdToDelete({
                id: el.ID,
                type: el.Type,
              })}
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => addToMoveList(el.ID, el.Type, true)}
            >
              Move
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                const s = { ...state };
                s.els[index].isRenaming = true;
                setState(s);
              }}
            >
              Rename
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    </Grid>
  </Grid>
);

const FolderProgress = ({ el }) => (
  <div
    style={{
      width: '10em',
    }}
  >
    <Box key={el.Name} display="flex" alignItems="center">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(el.Completion * 100)}%`}
        </Typography>
      </Box>
      <Box
        width="100%"
        mr={1}
        style={{
          marginLeft: '0.4em',
        }}
      >
        <LinearProgress
          variant="determinate"
          value={Math.round(el.Completion * 100)}
        />
      </Box>
    </Box>
  </div>
);

const ArrowButton = ({
  isOpen, index, setState, state,
}) => (
  <ListItemSecondaryAction>
    {isOpen ? (
      <ExpandLess
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          const s = { ...state };
          s.els[index].isOpen = false;
          setState(s);
        }}
      />
    ) : (
      <ExpandMore
        role="button"
        onClick={(e) => {
          e.stopPropagation();
          const s = { ...state };
          s.els[index].isOpen = true;
          setState(s);
        }}
      />
    )}
  </ListItemSecondaryAction>
);
