import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { TimelineDot } from '@material-ui/lab';
import { CARD_POINTS_LIST } from '../../config';

// Progress is of type 99.5%
const ProgressDots = ({
  score, DaysSinceLastUpdate, size, margin, bigOrSmall,
}) => {
  console.log(score, DaysSinceLastUpdate);
  const acc = [];
  for (let i = 0; i < CARD_POINTS_LIST.length; i += 1) {
    const el = CARD_POINTS_LIST[i];
    acc.push((
      <TimelineDot
        color="primary"
        key={el}
        variant={
          (score >= el) ? 'default' : 'outlined'
          }
        style={{
          marginTop: 0, marginBottom: 0, marginLeft: margin, padding: 0,
        }}
      >
        {(() => (DaysSinceLastUpdate >= el && score >= el
          ? (
            <AccessTimeIcon
              style={{
                height: `${size}`,
                width: `${size}`,
                color: 'white',
              }}
            />
          )
          : (
            <svg
              className="MuiSvgIcon-root"
              viewBox="0 0 300 300"
              style={{
                height: `${size}`,
                width: `${size}`,
              }}
            >
              {(() => (
                bigOrSmall ? (() => (
                  el > 10 ? (
                    <text
                      x="23"
                      y="225"
                      fontFamily="Verdana"
                      fontSize="200"
                    >
                      {el}
                    </text>
                  ) : (
                    <text
                      x="90"
                      y="225"
                      fontFamily="Verdana"
                      fontSize="200"
                    >
                      {el}
                    </text>
                  )
                ))() : ''
              ))()}
            </svg>
          ))
        )()}
      </TimelineDot>
    ));
  }
  return (
    <Box display="flex" alignItems="center">
      {acc}
    </Box>
  );
};

ProgressDots.defaultProps = {
  score: 0,
  DaysSinceLastUpdate: 0,
  size: '8px',
  margin: '0.2em',
  bigOrSmall: false,
};

ProgressDots.propTypes = {
  score: PropTypes.number,
  DaysSinceLastUpdate: PropTypes.number,
  size: PropTypes.string,
  margin: PropTypes.string,
  bigOrSmall: PropTypes.bool,
};

export default ProgressDots;
