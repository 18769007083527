// postRequest does a post with uri encoded details
export const postRequest = async (details, url) => {
  const formBody = [];
  for (const property in details) {
    if (details[property] === undefined) {
      continue;
    }
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }

  const r = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody.join('&'),
  });

  if (!r.ok) {
    throw (Error(await r.text()));
  }

  return r;
};

export const postRequestJSON = async (details, url) => {
  const r = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });

  if (!r.ok) {
    throw (Error(await r.text()));
  }

  return r;
};
