import React from 'react';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { AppBar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';

const classes = {
  button: {
    marginLeft: 16,
    justify: 'center',
    fontSize: '0.7em',
  },
};

const clearCookie = (history) => {
  document.cookie = 'JWT= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  history.push('/login');
};

export default function Header() {
  const history = useHistory();

  if (document.cookie.indexOf('JWT') === -1) {
    window.location.replace('/login');
  }

  return (
    <AppBar position="relative">
      <Toolbar style={{ flexGrow: 1 }}>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Wanki
        </Typography>
        <Button variant="contained" style={classes.button} color="primary" onClick={() => history.push('/')}>
          Home
        </Button>
        <Button variant="contained" style={classes.button} color="primary" onClick={() => history.push('/createCard')}>
          Create
        </Button>
        <Button variant="contained" style={classes.button} color="primary" onClick={() => history.push('/explore/')}>
          Explore
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button variant="contained" style={classes.button} color="primary" onClick={() => history.push('/profile')}>
          Profile
        </Button>
        <Button variant="contained" style={classes.button} color="primary" onClick={() => clearCookie(history)}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
