import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { CustomCard, getDetails } from '../Card';
import { postRequest } from '../../utils/network';
import { API_URLS } from '../../config';

const classes = {
  root: {
    marginTop: '2%',
  },
};

const CreateCard = ({
  folderPath, fullSize, noTitle, creationCallback,
}) => {
  const [inputs, setInputs] = useState({
    ...CustomCard.defaultProps.inputs,
    isBackSideHidden: false,
    folder: folderPath || CustomCard.defaultProps.folder,
  });

  const handleCreateCard = async () => {
    const d = getDetails(inputs);
    if (d === undefined) {
      console.log('failed to get details');
      return;
    }
    try {
      await postRequest(d, API_URLS.CREATE_CARD);
    } catch (e) {
      toastr.error(e);
      return;
    }

    setInputs({ ...CustomCard.defaultProps.inputs, folder: inputs.folder });
    if (creationCallback !== undefined) {
      creationCallback();
    }
  };

  return (
    <div style={classes.root}>
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12} sm={fullSize ? 12 : 6}>
          {!noTitle && (
          <Grid item xs={12}>
            <Typography style={classes.title} variant="h4">
              Create card
            </Typography>
          </Grid>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <CustomCard
                inputs={inputs}
                setInputs={setInputs}
                includes={() => (
                  <span>
                    <br />
                    <Grid item>
                      <Grid container justify="flex-end">
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            onClick={handleCreateCard}
                            color="primary"
                          >
                            Create
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </span>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
CreateCard.propTypes = {
  folderPath: PropTypes.string,
  fullSize: PropTypes.bool,
  noTitle: PropTypes.bool,
};
CreateCard.defaultProps = {
  folderPath: undefined,
  fullSize: false,
  noTitle: false,
};

export default CreateCard;
