/* eslint no-param-reassign: "off" */
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const cumulativeOffset = (element) => {
  let inEl = element;
  let top = 0; let
    left = 0;
  do {
    top += inEl.offsetTop || 0;
    left += inEl.offsetLeft || 0;
    inEl = inEl.offsetParent;
  } while (inEl);

  return {
    top,
    left,
  };
};

export const makeConfetti = ({
  number, containerId, size, height, explosionWidth, duration,
  latenessFactor, force,
}) => {
  const container = document.getElementById(containerId);
  const b = cumulativeOffset(container);

  const rect = container.getBoundingClientRect();

  const xs = (b.left + rect.width / 2);
  const ys = (b.top + rect.height / 2);

  const randomId = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

  let width = explosionWidth;
  const wobbleWidth = 30;
  const maxWidth = (window.innerWidth - xs) * 2 - wobbleWidth + 1;
  if ((width + xs) > window.innerWidth) {
    width = maxWidth;
  }

  // Create a container for all the confettis
  const el = document.createElement('span');
  // el.innerHTML = c;
  el.id = randomId;
  el.html = `
    <style>
      .confettis {
        will-change: transform, opacity;
      }
    </style>`;

  let acc = '';
  for (let i = 0; i < number; i += 1) {
    const nl = (Math.random() - 0.5) * width;
    const nh = (Math.random() * 0.3 + 1 - 0.3) * height;

    const lateness = Math.random() * latenessFactor;

    const css = `
      @keyframes confettiAn${randomId}${i}{
        0% {
          position: absolute;
          opacity: 1;
          transform: rotateY(0) rotate3d(1,1,1,0) translateX(0) translateY(0);
        }
        ${force + lateness}% {
          transform: translateY(-${nh}px) translateX(${nl}px);
        }
        100% {
          opacity: 0;
          transform: rotate3d(1,2,1,${2000 + 300 * Math.random()}deg)
          translateY(0)
          translateX(${nl}px)
          rotateY(${540 + Math.random() * 540}deg);
        }
      }`;

    acc += ReactDOMServer.renderToString((
      <span>
        <style>{css}</style>
        <span
          className="confettis"
          style={{
            position: 'absolute',
            top: `${ys}px`,
            left: `${xs}px`,
            width: `${Math.round(size + Math.random() * size * 0.3)}px`,
            height: `${Math.round(size * 2 + Math.random() * size * 0.3)}px`,
            backgroundColor: `#${((1 << 24) * Math.random() | 0).toString(16)}`,
            animationName: `confettiAn${randomId}${i}`,
            animationDuration: `${duration}s`,
            animationTimingFunction: 'linear',
            animationDelay: `${Math.random() * 0.3}s`,
            animationFillMode: 'forwards',
            opacity: 0,
            zIndex: 1000,
          }}
        />
      </span>
    ));
  }
  el.innerHTML = acc;

  document.getElementsByTagName('html').item(0).prepend(el);

  setTimeout(() => {
    document.getElementById(el.id).remove();
  }, (duration + 0.3) * 1000);
};
