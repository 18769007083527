export const EXPLORE_CHANGE_PATH = 'EXPLORE_CHANGE_PATH';

const defaultState = {
  list: [],
  oldPath: undefined,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EXPLORE_CHANGE_PATH:
      return {
        ...state,
        list: action.payload.list,
        oldPath: action.payload.oldPath,
      };

    default:
      return state;
  }
};
