import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import { API_URLS } from '../../config';

function Graph({ containerId }) {
  const [state, setState] = useState({
    width: 0,
    data: [],
  });

  const getData = async () => {
    try {
      const r = await fetch(API_URLS.GET_GRAPH_POINTS);
      const d = await r.json();
      setState({
        ...state,
        data: d,
        width: document.getElementById(containerId).clientWidth * (2 / 3),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (state.width !== 0) {
      return;
    }

    getData();
  });

  if (state.width === 0) {
    return '';
  }

  return (
    <LineChart
      width={state.width}
      height={state.width * (2 / 3)}
      data={state.data}
      responsive
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Points" stroke="#82ca9d" />
    </LineChart>
  );
}

Graph.defaultProps = {};

Graph.propTypes = {
  containerId: PropTypes.string.isRequired,
};

export default Graph;
