// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from 'cross-fetch';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_URLS } from '../../config';

export default function Asynchronous({ style, onChange, value }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(API_URLS.FOLDERS_AUTOCOMPLETE);
      const countries = await response.json();

      if (active) {
        // setOptions(Object.keys(countries).map((key) => ({"name": countries[key]})));
        setOptions(countries);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(e, v) => { onChange(v); }}
      options={options}
      loading={loading}
      selectOnFocus
      handleHomeEndKeys
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label="Folder"
          style={style}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}
