import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ShowCard from './components/ShowCard';
import Header from './components/Header';
import Home from './components/Home';
import CreateCard from './components/CreateCard';
import Explore from './components/Explore';
import { checkSubscribe } from './utils/subscribe-push';
import Register from './components/Register';
import Login from './components/Login';
import Profile from './components/Profile';
import './css/App.css';

const App = () => (
  <Router>
    <Switch>

      <Route path="/register">
        <Register />
      </Route>
      <Route path="/login">
        <Login />
      </Route>

      <Route>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/createCard">
              <CreateCard />
            </Route>
            <Route path="/explore/:folderId">
              <Explore />
            </Route>
            <Route path="/explore">
              <Explore />
            </Route>
            <Route path="/showCard/:cardId">
              <ShowCard />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  </Router>
);

checkSubscribe();

export default App;
