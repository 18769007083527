export const APIURL = '';

export const API_URLS = {
  CARD_GET_NEXT_TRYS: `${APIURL}/api/cards/nexttrys`,
  CARD_SHOW_FULLPATH: `${APIURL}/api/cards/show/fromfullpath/`,
  CARD_SHOW_ID: `${APIURL}/api/cards/show/fromid/`,
  CREATE_CARD: `${APIURL}/api/cards/create`,
  CREATE_FOLDER: `${APIURL}/api/folders/create`,
  DELETE_CARD: `${APIURL}/api/cards/delete`,
  DELETE_FOLDER: `${APIURL}/api/folders/delete`,
  RENAME_CARD: `${APIURL}/api/cards/rename`,
  DELETE_FOLDER_FROM_ID: `${APIURL}/api/folders/deletefromid`,
  FIND_ALL: `${APIURL}/api/find`,
  FOLDERS_AUTOCOMPLETE: `${APIURL}/api/folders/find`,
  FOLDER_LIST_INSIDE: `${APIURL}/api/folders/fromid/`,
  GET_GRAPH_POINTS: `${APIURL}/api/graphs/getpoints`,
  GET_WEB_PUB_KEY: `${APIURL}/api/push/getkey`,
  LIST: `${APIURL}/api/folders/ls`,
  MOVE_FOLDER: `${APIURL}/api/folders/move`,
  REGISTER_WEB_WORKER: `${APIURL}/api/push/register`,
  UPDATE_CARD: `${APIURL}/api/cards/update`,
  RENAME_FOLDER: `${APIURL}/api/folders/rename`,
  SEARCH: `${APIURL}/api/search`,
  MOVE_ELEMENTS: `${APIURL}/api/elements/move`,
  REGISTER: `${APIURL}/api/register`,
  LOGIN: `${APIURL}/api/login`,
  GET_ROOT_ID: `${APIURL}/api/folders/getrootid`,
  GET_PROFILE: `${APIURL}/api/profile/get`,
  SET_PROFILE: `${APIURL}/api/profile/set`,
};

export const CARD_MAX_POINTS = 16;
export const CARD_POINTS_LIST = [1, 2, 4, 8, 16];
