import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import ReactQuill from 'react-quill';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '../Autocomplete';
import 'react-quill/dist/quill.snow.css';

const classes = {
  paper: {
    textAlign: 'center',
  },
  card: {
    flex: 3,
  },
  firstInput: {
    marginTop: '0%',
  },
  title: {
    marginBottom: '2%',
  },
  input: {
    marginTop: '2%',
    width: '100%',
    size: 'large',
    flex: 4,
  },
  cardContainer: {
    flex: 1,
  },
};

export const inputsFieldsName = [
  'name',
  'front',
  'frontType',
  'back',
  'backType',
  'folderPath',
  'tags',
];

export const getDetails = (inputs) => {
  const details = {
    id: inputs.id,
    name: inputs.name,
    front: inputs.frontSide,
    frontType: inputs.frontType,
    back: inputs.backSide,
    backType: inputs.backType,
    folderPath: inputs.folder,
    tags: inputs.tags,
  };

  return details;
};

function SpecialInput({
  inputs, setInputs, inputName, type, name, onFocus, value, typeName,
}) {
  const ret = [];
  if (type === 'quill') {
    let quillStyle = {};
    if (inputs.isBackSideHidden && inputName === 'backSide') {
      quillStyle = {
        filter: 'blur(5px)',
        backgroundColor: '#ccc',
      };
    }
    ret.push((
      <Grid
        item
        key="gridInput"
        style={{
          marginTop: '4%',
        }}
        onClick={() => {
          if (inputs.isBackSideHidden && inputName === 'backSide') {
            setInputs({ ...inputs, isBackSideHidden: false });
          }
        }}
      >
        <Grid container justify="flex-start">
          <FormLabel component="legend">{name}</FormLabel>
        </Grid>
        <ReactQuill
          theme="snow"
          value={inputs[inputName]}
          style={quillStyle}
          onChange={(e) => {
            const s = { ...inputs };
            s[inputName] = e;
            setInputs(s);
          }}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'image'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image',
          ]}
        />
      </Grid>
    ));
  } else {
    ret.push((
      <TextField
        multiline
        key="inputName"
        style={classes.input}
        value={value}
        onFocus={onFocus}
        onChange={(e) => {
          const s = { ...inputs };
          s[inputName] = e.target.value;
          setInputs(s);
        }}
        label={name}
      />
    ));
  }

  ret.push((
    <span key="switchContainer">
      <Grid container justify="flex-end">
        <Grid item>

          <Grid container alignItems="center">
            <Grid item>
              Simple
            </Grid>
            <Grid item>
              <Switch
                checked={type !== 'input'}
                style={{
                }}
                onChange={(e) => {
                  const s = { ...inputs };
                  s[typeName] = e.target.checked ? 'quill' : 'input';
                  if (!e.target.checked) {
                    s[inputName] = '';
                  }
                  setInputs(s);
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Grid>
            <Grid item>
              Quill
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  ));
  return ret;
}

export function CustomCard({ inputs, setInputs, includes }) {
  const addIncludes = () => {
    if (typeof (includes) === 'function') {
      return includes();
    }
    return '';
  };

  const backSide = inputs.isBackSideHidden && inputs.backSide !== ''
    ? '..........' : inputs.backSide;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              style={{ ...classes.input, ...classes.firstInput }}
              value={inputs.name}
              onChange={(e) => { setInputs({ ...inputs, name: e.target.value }); }}
              label="Name"
            />
            {' '}
            <br />
            <SpecialInput
              key="frontSide"
              value={inputs.frontSide}
              inputs={inputs}
              setInputs={setInputs}
              type={inputs.frontType}
              inputName="frontSide"
              typeName="frontType"
              name="Front side"
            />
            <SpecialInput
              key="backSide"
              value={backSide}
              inputs={inputs}
              setInputs={setInputs}
              type={inputs.backType}
              inputName="backSide"
              typeName="backType"
              onFocus={() => { setInputs({ ...inputs, isBackSideHidden: false }); }}
              name="Back side"
            />
            <Autocomplete
              onChange={(v) => { setInputs({ ...inputs, folder: v }); }}
              value={inputs.folder}
              style={classes.input}
            />
            <TextField
              style={classes.input}
              onChange={(e) => { setInputs({ ...inputs, tags: e.target.value }); }}
              value={inputs.tags}
              label="Tags"
            />
            {addIncludes()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CustomCard.propTypes = {
  inputs: PropTypes.shape({
    id: PropTypes.number,
    frontSide: PropTypes.string,
    frontType: PropTypes.string,
    backSide: PropTypes.string,
    backType: PropTypes.string,
    tags: PropTypes.string,
    folder: PropTypes.string,
    name: PropTypes.string,
    score: PropTypes.number,
    isBackSideHidden: PropTypes.bool,
    oldCard: PropTypes.object,
  }),
  setInputs: PropTypes.func,
  includes: PropTypes.func,
};

CustomCard.defaultProps = {
  inputs: {
    id: undefined,
    frontSide: '',
    frontType: 'input',
    backSide: '',
    backType: 'input',
    tags: '',
    folder: '',
    name: '',
    score: 0,
    folderId: 0,
    isBackSideHidden: true,
    oldCard: {},
  },
  setInputs: undefined,
  includes: undefined,
};
