import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Button from '@material-ui/core/Button';
import HumanizeDuration from 'humanize-duration';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { API_URLS } from '../../config';
import { postRequest } from '../../utils/network';
import Graph from '../Graph/index';

const classes = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '20px',
    textAlign: 'center',
  },
};

const loadRows = async (setRows) => {
  try {
    const r = await postRequest(undefined, API_URLS.CARD_GET_NEXT_TRYS);
    const cs = await r.json();
    setRows(cs);
  } catch (e) {
    toastr.error(e);
  }
};

// checkPermission stores the actual permission in the state
const checkPermissions = (setPermissionState) => {
  Notification.requestPermission((status) => {
    setPermissionState(status === 'granted');
  });
};

function Home() {
  const [rows, setRows] = useState([]);
  const [permissionState, setPermissionState] = useState(true);
  const history = useHistory();

  useEffect(() => {
    loadRows(setRows);
    checkPermissions(setPermissionState);
  }, [setRows]);

  return (
    <Grid container style={{ marginTop: '2%' }} justify="center" spacing={1}>
      {(() => (!permissionState ? (
        <Modal
          open
        >
          <div style={classes.modal}>
            <h2>Notifications</h2>
            <p>
              To properly work, the app need to use notifications, would you activate them ?
            </p>
            <Grid justify="space-evenly" container xs={12}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => { setPermissionState(true); }}
                  color="default"
                >
                  <span role="img" aria-label="sad" style={{ marginRight: '0.5em' }}>😔</span>
                  Cancel
                  <span role="img" aria-label="sad" style={{ marginLeft: '0.5em' }}>😔</span>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => { checkPermissions(setPermissionState); }}
                  color="primary"
                >
                  <span role="img" aria-label="party" style={{ marginRight: '0.5em' }}>🎉</span>
                  Activate
                  <span role="img" aria-label="party" style={{ marginLeft: '0.5em' }}>🎉</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      )
        : '')
      )()}

      <Grid item xs={12} sm={5}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Score</TableCell>
                <TableCell align="center">Next update</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((el) => (
                <TableRow
                  hover
                  onClick={() => {
                    history.push(`/showCard/${el.ID}`);
                  }}
                  key={el.ID}
                >
                  <TableCell component="th" scope="row">
                    <a href="#0" style={{ textDecoration: 'none', color: 'black' }}>
                      {' '}
                      {el.Name}
                      {' '}
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    {el.Score}
                  </TableCell>
                  <TableCell align="center">
                    {(() => (el.NextTry < 0 ? '' : '-') + HumanizeDuration(el.NextTry * 1000, { round: true }))()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid id="chartContainer" item container xs={12} sm={6} justify="center">
        <Graph containerId="chartContainer" />
      </Grid>

    </Grid>
  );
}

export default Home;
