import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import HumanizeDuration from 'humanize-duration';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeConfetti } from '../../utils/confetti';
import ProgressDots from '../ProgressDots';
import { API_URLS } from '../../config';
import { CustomCard, getDetails } from '../Card';
import { postRequest } from '../../utils/network';

const classes = {
  root: {
    marginTop: '2%',
    flexGrow: 1,
  },
  input: {
    marginTop: '2%',
    width: '100%',
    size: 'large',
    flex: 4,
  },
  progressBar: {
    marginTop: '5%',
  },
  card: {
    marginTop: '2%',
    width: '100%',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: '20px',
  },
};

const [sdiv4, sdiv2, s0, smul2, smul4] = ['/4', '/2', '0', '*2', '*4'];

const loadCard = async (cardId, setInputs) => {
  try {
    const r = await fetch(API_URLS.CARD_SHOW_ID + cardId);
    const j = await r.json();
    const newCard = {
      id: j.ID,
      name: j.Name,
      frontSide: j.FrontSide,
      frontType: j.FrontType,
      backSide: j.BackSide,
      backType: j.BackType,
      folder: j.FolderPath,
      folderId: j.FolderID,
      tags: j.Tags.join(', '),
      lastTry: j.LastTry,
      score: j.Score,
      isBackSideHidden: true,
      timeSinceLastUpdate: j.TimeSinceLastUpdate,
    };
    setInputs({
      ...CustomCard.defaultProps.inputs,
      ...newCard,
      oldCard: { ...newCard },
    });
  } catch (e) {
    toastr.error(e);
  }
};

const showConfetti = async (id) => {
  makeConfetti({
    containerId: id,
    number: 50,
    size: 5,
    height: 400,
    explosionWidth: 600,
    duration: 4,
    latenessFactor: 5,
    force: 8,
  });
};

function ShowCard() {
  const { cardId } = useParams();

  const [inputs, setInputs] = useState({ ...CustomCard.defaultProps.inputs });
  const [isModalOpen, setModalOpen] = useState(false);

  const history = useHistory();

  const handleDeleteCard = async () => {
    try {
      await postRequest(undefined, `${API_URLS.DELETE_CARD}/${inputs.id}`);
    } catch (e) {
      toastr.error(e);
      return;
    }

    history.push(`/explore/${inputs.folderId}`);
  };

  const handleUpdateCard = async (updateScore) => {
    // Remove everything that hasn't changed
    const ni = {};
    Object.entries(inputs).forEach((key) => {
      if (key[0] === 'oldCard') {
        return;
      }

      if (inputs[key[0]] !== inputs.oldCard[key[0]]) {
        ni[key[0]] = inputs[key[0]];
      }
    });

    // Add mandatory fieds
    ni.id = inputs.id;
    ni.folder = inputs.folder;
    ni.tags = inputs.tags;

    const d = getDetails(ni);
    if (d === undefined) {
      console.log('failed to get details');
      return;
    }

    let updateLastTry = false;
    d.score = inputs.score;
    if (updateScore !== undefined) {
      switch (updateScore) {
        case sdiv4:
          d.score = Math.floor(inputs.score / 4);
          if (d.score < 1) {
            d.score = 0;
          }
          break;
        case sdiv2:
          d.score = Math.floor(inputs.score / 2);
          if (d.score < 1) {
            d.score = 0;
          }
          break;
        case smul2:
          if (d.score < 1) {
            d.score = 1;
            break;
          }
          d.score = Math.floor(inputs.score * 2);
          break;
        case smul4:
          if (d.score < 1) {
            d.score = 4;
            break;
          }
          d.score = Math.floor(inputs.score * 4);
          break;
        case s0:
          break;
        default:
      }
      updateLastTry = true;
    }
    d.updateLastTry = updateLastTry;

    try {
      await postRequest(d, API_URLS.UPDATE_CARD);
    } catch (e) {
      toastr.error(e);
      return;
      // TODO: Do something
    }

    loadCard(cardId, setInputs);
  };

  useEffect(() => {
    if (cardId !== '') {
      loadCard(cardId, setInputs);
    }
  }, [cardId, setInputs]);

  const modalBody = (
    <div style={classes.modal}>
      <h2 id="simple-modal-title">Are you sure ?</h2>
      <p id="simple-modal-description">
        Please confirm the card deletion
        <br />
        <br />
        <Button
          variant="contained"
          onClick={handleDeleteCard}
          style={classes.button}
          color="secondary"
        >
          Delete
        </Button>
      </p>
    </div>
  );

  const openDeleteModal = () => {
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setModalOpen(false);
  };

  return (
    <div style={classes.root}>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} m={12}>
          <Typography style={classes.title} variant="h4">
            Update card
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container item spacing={0}>
            <Grid item xs={12}>
              <CustomCard
                inputs={inputs}
                setInputs={setInputs}
                includes={() => (
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        style={classes.input}
                        type="number"
                        onChange={(e) => { setInputs({ ...inputs, score: e.target.value }); }}
                        value={inputs.score}
                        label="Score"
                      />
                    </Grid>
                    <Grid container style={{ marginTop: '5%' }} justify="center" xs={12}>
                      <ProgressDots
                        score={inputs.score}
                        DaysSinceLastUpdate={Math.floor(inputs.timeSinceLastUpdate / 86400)}
                        size="2em"
                        bigOrSmall="true"
                      />
                    </Grid>
                    <Grid item container justify="center" style={{ marginTop: '2%' }}>
                      {HumanizeDuration(inputs.timeSinceLastUpdate * 1000, { round: true })}
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card style={classes.card}>
                  <CardContent>
                    <Grid container spacing={1} justify="center">
                      <Grid item md={6} xs={12}>
                        <Grid container justify="center" spacing={1}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={classes.button}
                              id="buttonDiv4"
                              onClick={() => handleUpdateCard(sdiv4)}
                            >
                              /4
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={classes.button}
                              id="buttonDiv2"
                              onClick={() => handleUpdateCard(sdiv2)}
                            >
                              /2
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              style={classes.button}
                              id="buttonX2"
                              onClick={() => {
                                showConfetti('buttonX2');
                                handleUpdateCard(smul2);
                              }}
                            >
                              x2
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              style={classes.button}
                              id="buttonX4"
                              onClick={() => {
                                showConfetti('buttonX4');
                                handleUpdateCard(smul4);
                              }}
                            >
                              X4
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item md={5} xs={12} spacing={1} justify="space-evenly">
                        <Grid item>
                          <Button variant="contained" onClick={openDeleteModal} style={classes.button} color="secondary">
                            Delete
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" onClick={handleUpdateCard} style={classes.button} color="default">
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={closeDeleteModal}
      >
        {modalBody}
      </Modal>
    </div>
  );
}

export default ShowCard;
