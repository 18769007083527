import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { useForm } from 'react-hook-form';
import { API_URLS } from '../../config';
import { postRequest, postRequestJSON } from '../../utils/network';
import { TextField, Grid } from '../../../node_modules/@material-ui/core/index';
import Typography from '../../../node_modules/@material-ui/core/Typography/Typography';
import Button from '../../../node_modules/@material-ui/core/Button/Button';

const style = {
  TextField: {
    width: '100%',
    marginTop: '2%',
  },
};

const loadProfile = async (setValue) => {
  try {
    const p = await postRequest(undefined, API_URLS.GET_PROFILE);
    const j = await p.json();
    setValue('email', j.Email);
    setValue('username', j.Username);
    console.log(j);
  } catch (e) {
    toastr.error(e);
  }
};

const submit = async (values) => {
  if (values.password !== values.confirmPassword) {
    toastr.error('Password and confirmation must be the same');
  }

  try {
    await postRequestJSON({
      username: values.username,
      password: values.password,
      email: values.email,
    }, API_URLS.SET_PROFILE);
  } catch (e) {
    toastr.error(e);
  }
};

const Profile = () => {
  const {
    handleSubmit, register, errors, setValue,
  } = useForm();

  useEffect(() => {
    loadProfile(setValue);
  }, [setValue]);

  if (errors.password) {
    toastr.error('Password length must be greater than 8 characters');
  } else if (errors.confirmPassword) {
    toastr.error('Password and confirmation must be the same');
  }

  return (
    <span>
      <Typography style={{ marginTop: '2%' }} variant="h4">
        Profile
      </Typography>
      <form onSubmit={handleSubmit(submit)} noValidate>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="username"
              fullWidth
              id="username"
              label="Username"
              defaultValue=" "
              inputRef={register({
                required: 'Required',
              })}
            />
            <TextField
              style={style.TextField}
              id="email"
              name="email"
              label="Email"
              defaultValue=" "
              inputRef={register({
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '5%' }} justify="center" xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              required
              type="password"
              hidden
              fullWidth
              id="password"
              label="Password"
              inputRef={register({
                required: 'Required',
                validate: (value) => value.length > 8,
              })}
            />
            <TextField
              name="confirmPassword"
              required
              type="password"
              error={errors.confirmPassword}
              fullWidth
              hidden
              id="confirmPassword"
              label="Confirm password"
              inputRef={register({
                validate: (value) => value.length > 8,
              })}
            />
            <Grid item style={{ marginTop: '2%' }} container xs={12} justify="flex-end">
              <Grid item>
                <Button
                  className=""
                  type="submit"
                  color="default"
                  variant="contained"
                  inputRef={register({
                    validate: (value) => value.length > 8,
                  })}
                >
                  Update password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </span>
  );
};

Profile.defaultProps = {};

Profile.propTypes = {
};

export default Profile;
