/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const colors = require('@material-ui/core/colors');

const style = {
  select: {
    minWidth: 200,
    background: 'white',
    // color: colors.deepPurple[200],
    color: '#878efc',
    fontWeight: 200,
    borderRadius: 12,
    paddingLeft: 24,
    paddingTop: 4,
    borderStyle: 'solid',
    borderWidth: '1px',
    paddingBottom: 4,
    boxShadow: '0px 8px 10px -3px rgba(0,0,0,0.14)',
    '&:focus': {
      borderRadius: 12,
      background: 'white',
      borderColor: colors.deepPurple[100],
    },
  },
  icon: {
    color: colors.deepPurple[300],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      fontWeight: 200,
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& li:hover': {
      background: colors.deepPurple[100],
    },
    '& li.Mui-selected': {
      color: 'white',
      background: colors.deepPurple[400],
    },
    '& li.Mui-selected:hover': {
      background: colors.deepPurple[500],
    },
  },
};

const MinimalSelect = ({
  items, value, onChange,
}) => {
  const iconComponent = () => (
    <ExpandMoreIcon
      style={style.icon}
    />
  );

  // moves the menu below the select input
  const menuProps = {
    style: {
      paper: style.paper,
      list: style.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        MenuProps={menuProps}
        style={style.select}
        IconComponent={iconComponent}
        value={value}
        onChange={onChange}
      >
        {
          items.map((el) => (
            <MenuItem
              key={el.name}
              value={el.value}
            >
              {el.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

MinimalSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MinimalSelect;
