import { API_URLS } from '../config';
import { postRequest } from './network';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}

export function subscribe() {
  console.log('subscribing');
  navigator.serviceWorker.ready
    .then(async (registration) => {
      const vapidPublicKeyPromise = await fetch(API_URLS.GET_WEB_PUB_KEY);
      const vapidPublicKey = await vapidPublicKeyPromise.text();
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      });
    })
    .then(async (subscription) => {
      console.log('registered');
      const ezKeys = JSON.parse(JSON.stringify(subscription));
      try {
        await postRequest({
          endpoint: ezKeys.endpoint,
          auth: ezKeys.keys.auth,
          p256dh: ezKeys.keys.p256dh,
        }, API_URLS.REGISTER_WEB_WORKER);
      } catch (e) {
        console.log(e);
        navigator.serviceWorker.ready
          .then(async (registration) => {
            console.log('unregistred');
            registration.unregister();
          });
      }
    })
    .catch((err) => console.error(err));
}

export function checkSubscribe() {
  Notification.requestPermission().then(() => {
    console.log('notification permission request submitted');
  });
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
    navigator.serviceWorker.ready
      .then((registration) => registration.pushManager.getSubscription())
      .then((subscription) => {
        if (!subscription) {
          subscribe();
        }
      });
  }
}
